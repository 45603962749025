import {IOptionSelectionVariant, IProductOptionsItem} from '../../types/productDef';
import {ProductInventoryStatus} from '../../constants';
import {IProductDTO, UserInput, UserInputErrors} from '../../types/app-types';
import {QuantityCalculator} from './quantityCalculator';
import {last, first} from '../../commons/utils';
import {IProductCustomTextAnswer} from '@wix/wixstores-client-core/dist/es/src/types/product';
import {IProductOptionSelection, ProductOptionType} from '@wix/wixstores-graphql-schema/dist/es/src';
import {get} from 'lodash';

export function selectProductOptionItems(product: IProductDTO): IProductOptionsItem[] {
  return product.options as IProductOptionsItem[];
}

export function selectProductOptionItemsByType(product: IProductDTO, type: ProductOptionType): IProductOptionsItem {
  return product.options.find(o => o.optionType === type) as IProductOptionsItem;
}

export function inStock(product: IProductDTO, variant?: IOptionSelectionVariant) {
  const ref = variant || product;

  if (product.isInStock === false) {
    return false;
  }

  if (!variant && product.isInStock !== undefined) {
    return product.isInStock;
  }

  if (product.isTrackingInventory) {
    if (variant) {
      return ref.inventory.quantity > 0;
    }
    return product.inventory.quantity > 0 || product.productItems.some(item => item.inventory.quantity > 0);
  }

  return ref.inventory.status === ProductInventoryStatus.IN_STOCK;
}

export function userInputsFactory(): UserInput | UserInputErrors {
  return {selection: [], text: [], quantity: [], subscriptionPlan: []};
}

export function validateUserInputs(product: IProductDTO, userInputs: UserInput): UserInputErrors {
  const errors = userInputsFactory() as UserInputErrors;
  const quantityRange = QuantityCalculator.getQuantitiesRange(
    product,
    userInputs.selection as IProductOptionSelection[]
  );
  const userQuantity = Number(userInputs.quantity[0]);

  product.options.forEach((_, i) => {
    errors.selection[i] = !userInputs.selection[i];
  });

  if (product.customTextFields) {
    product.customTextFields.forEach((item, i) => {
      errors.text[i] = item.isMandatory === true && !userInputs.text[i];
    });
  }

  errors.quantity = [
    (isNaN(userQuantity) || userQuantity < first(quantityRange) || userQuantity > last(quantityRange)) === true,
  ];

  const isProductSubscription = !!get(product.subscriptionPlans, ['list']);
  const isSelectedPlan = !!get(userInputs.subscriptionPlan, ['0']);
  errors.subscriptionPlan = [isProductSubscription && !isSelectedPlan];

  return errors;
}

export function formatCustomTextFields(product: IProductDTO, userInputs: UserInput): IProductCustomTextAnswer[] {
  const customTextFields: IProductCustomTextAnswer[] = [];

  return (product.customTextFields || []).reduce((acc, field, i) => {
    acc.push({
      customText: {title: field.title},
      answer: userInputs.text[i] || '',
    });

    return acc;
  }, customTextFields);
}

export function hasVariantInStock(product: IProductDTO): boolean {
  return product.productItems.some((option: IOptionSelectionVariant) => inStock(product, option));
}
