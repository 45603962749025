import React, {Component} from 'react';
import {ProvidedGlobalProps, withGlobalProps} from '../../../providers/globalPropsProvider';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {ISubscriptionPlan, SubscriptionPlan} from '../SubscriptionPlan/SubscriptionPlan';
import s from './SubscriptionPlans.scss';
import {ErrorTooltipPlacement, UserInputType} from '../../../constants';
import {get} from 'lodash';
import {ErrorTooltip} from '../ErrorTooltip/ErrorTooltip';

export const enum SubscriptionPlansDataHooks {
  TITLE = 'plans-title',
}

export interface ISubscriptionPlansState {
  plans: ISubscriptionPlan[];
}

export interface ISubscriptionPlansProps extends ProvidedGlobalProps, IProvidedTranslationProps {
  submitted: boolean;
  errorPlacement?: ErrorTooltipPlacement;
}

@withGlobalProps
@withTranslations('globals.texts')
export class SubscriptionPlans extends Component<ISubscriptionPlansProps, ISubscriptionPlansState> {
  public state = {
    plans: [],
  };

  public componentDidMount() {
    const plans: ISubscriptionPlan[] = this.props.globals.subscriptionPlans;
    this.setState({plans});
  }

  private onItemClicked(item: ISubscriptionPlan) {
    this.handleUserInput(item);
  }

  public handleUserInput = (input: ISubscriptionPlan): void => {
    const {handleUserInput} = this.props.globals;
    handleUserInput && handleUserInput(UserInputType.SubscriptionPlan, input, 0);
    this.props.globals.validate();
  };

  private isSelected(item: ISubscriptionPlan) {
    const subscriptionPlan = get(this.props.globals.userInputs.subscriptionPlan, ['0']);

    if (!subscriptionPlan) {
      return false;
    }

    if (subscriptionPlan.isOneTimePurchase && item.isOneTimePurchase) {
      return true;
    }

    return subscriptionPlan.id === item.id;
  }

  private renderError(): JSX.Element {
    if (!this.props.submitted) {
      return null;
    }

    const {
      errorPlacement,
      globals: {userInputErrors},
    } = this.props;

    if (userInputErrors.subscriptionPlan[0]) {
      return <ErrorTooltip message={'MISSING_KEY___'} placement={errorPlacement} />;
    }

    return null;
  }

  public render() {
    const {t} = this.props;

    return (
      <div>
        <div className={s.title} data-hook={SubscriptionPlansDataHooks.TITLE}>
          {t('PRODUCT_PAGE_PURCHASE_OPTIONS_TITLE')}
          {this.renderError()}
        </div>
        {this.state.plans.map((item, index) => (
          <SubscriptionPlan
            key={index}
            className={s.planItem}
            plan={item}
            isSelected={this.isSelected(item)}
            onClick={() => {
              this.onItemClicked(item);
            }}></SubscriptionPlan>
        ))}
      </div>
    );
  }
}
